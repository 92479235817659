import requestResult from '@/common/request/request'
import useRequest from '@/hooks/useRequest'

export type RmInfoSchema = {
  countryCode: number;
  rechargeAmount: number;
  mtTradeAmount: number;
}

export type AddInfoSchema = {
  recipientAddress: string;
  recipientCity: string;
  recipientMobile: string;
  recipientName: string;
  recipientProvince: string;
  recipientZipCode: string;
  giftStatus: number;
}

export type GiftListSchema = {
  giftId: number;
  giftName: string;
  url: string;
  depositAmount: number;
  tradeAmount: number;
  chooseStatus: number;
}

export const readRmInfo = requestResult<RmInfoSchema>('/api/query/rmInfo')
export const readInputInfo = requestResult<AddInfoSchema>('/api/gift/info')
export const editAddress = requestResult('/api/gift/edit')
export const readGiftList = requestResult<GiftListSchema[]>('/api/gift/list')

export const useEditAddress = () => {
  const [commit, progress] = useRequest(editAddress)
  const request = async (data: Data) => {
    await commit(data)
  }
  return { request, progress }
}
